import React, { createContext, useState, useEffect } from "react";

export const ShopContext = createContext(null);

const ShopContextProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [maincategory, setMaincategory] = useState([]);
  const [count, setCount] = useState(0);
  const [cartItems, setCartItems] = useState(() => {
    // Retrieve cart items from localStorage on initial load
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : {};
  });

  const [cartCount, setCartCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [shippingFee, setShippingFee] = useState(50);

  useEffect(() => {
    fetchProducts();
    fetchMaincategorydata();
    getProductcount();
  }, []);

  useEffect(() => {
    // Save cart items to localStorage whenever it changes
    localStorage.setItem("cart", JSON.stringify(cartItems));

    // Calculate cart count whenever cart items change
    const totalCount = Object.values(cartItems).reduce(
      (acc, curr) => acc + curr,
      0
    );
    setCartCount(totalCount);

    // Calculate total price whenever cart items change
    let value = 0;
    Object.entries(cartItems).forEach(([itemId, quantity]) => {
      const product = products.find((product) => product._id === itemId);
      if (product) {
        value += quantity * product.offerprice;
      }
    });
    setSubtotalPrice(value);

    // Calculate subtotal price whenever cart items change
    // const shippingFee = 40;
    setTotalPrice(shippingFee + subtotalPrice);
  }, [cartItems, products, subtotalPrice]);

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_FURL + "/api/kaleproduct/productdatalist"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error.message);
    }
  };

  const fetchMaincategorydata = async (id) => {
    const res = await fetch(
      process.env.REACT_APP_FURL +
        "/api/catego/parentcategorynull?id=" +
        `${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    if (res.status === 404 || !data) {
      alert("error");
    } else {
      setMaincategory(data);
      console.log("get main-category and sub-category data", maincategory);
    }
  };

  const getProductcount = async () => {
    const res = await fetch(
      process.env.REACT_APP_FURL + "/api/kaleproduct/countproduct",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    console.log(data);
    if (res.status === 404 || !data) {
      alert("error");
    } else {
      setCount(data);
      console.log("product count");
    }
  };

  const addToCart = (itemId, quantity) => {
    setCartItems((prev) => {
      const updatedCart = { ...prev };
      updatedCart[itemId] = prev[itemId] ? prev[itemId] + quantity : quantity;
      return updatedCart;
    });
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => {
      const updatedCart = { ...prev };
      if (updatedCart[itemId] > 0) {
        updatedCart[itemId]--;
        if (updatedCart[itemId] === 0) {
          delete updatedCart[itemId];
        }
      }
      return updatedCart;
    });
  };

  const DeleteFromCart = (itemId, quantityToRemove) => {
    setCartItems((prev) => {
      const updatedCart = { ...prev };
      if (updatedCart[itemId] > 0) {
        if (updatedCart[itemId] > quantityToRemove) {
          updatedCart[itemId] -= quantityToRemove;
        } else {
          delete updatedCart[itemId];
        }
      }
      return updatedCart;
    });
  };

  const resetCart = () => {
    setCartItems({});
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const contextValue = {
    products,
    maincategory,
    cartItems,
    cartCount,
    shippingFee,
    totalPrice,
    subtotalPrice,
    addToCart,
    removeFromCart,
    DeleteFromCart,
    resetCart,
    scrollToTop,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
