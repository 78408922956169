import React from "react"
import CTASection from "../components/About/CTASection"
import FeaturesSection from "../components/About/FeaturesSection"
import MissionVisionSection from "../components/About/MissionVisionSection"
import VideoGallery from "../components/About/VideoGallery"
import Gallery from "../components/About/Gallery"

const About = () => {
  return (
    <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="71">
      <CTASection />
      <FeaturesSection />
      <MissionVisionSection />
      <VideoGallery />
      <Gallery />
    </div>
  )
}

export default About
