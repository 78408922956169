import React from "react"
import CTASection from "../components/Blog/CTASection"
import Sidebar from "../components/Blog/Sidebar"
import BlogContent from "../components/Blog/BlogContent"

const Blog = () => {
  return (
    <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="71">
      <CTASection />
      <section className="section contact team" id="blog">
        <div className="container">
          <div
            className="parentbox"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Sidebar />
            <BlogContent />
          </div>
        </div>
      </section>
      <button onClick={() => window.scrollTo(0, 0)} id="back-to-top">
        <span className="mdi mdi-arrow-up"></span>
      </button>
    </div>
  )
}

export default Blog
