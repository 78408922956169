import React from "react"

const TermsContent = () => {
  return (
    <section className="section team" id="features">
      <div id="particles-js" style={{ zIndex: -1 }}></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-8">
              <h2 className="heading">Terms and Conditions</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p>
              Welcome to Kales Agro Research and Development Private Limited.
              These terms and conditions outline the rules and regulations for
              the use of Kales Agro Research and Development Private Limited's
              website.
            </p>
            <h3>1. Introduction</h3>
            <p>
              By accessing this website, we assume you accept these terms and
              conditions. Do not continue to use Kales Agro Research and
              Development Private Limited if you do not agree to take all of the
              terms and conditions stated on this page.
            </p>
            <h3>2. Intellectual Property Rights</h3>
            <p>
              Other than the content you own, under these Terms, Kales Agro
              Research and Development Private Limited and/or its licensors own
              all the intellectual property rights and materials contained in
              this Website. You are granted limited license only for purposes of
              viewing the material contained on this Website.
            </p>
            <h3>3. Restrictions</h3>
            <p>You are specifically restricted from all of the following:</p>
            <ul>
              <li>Publishing any Website material in any other media;</li>
              <li>
                Selling, sublicensing and/or otherwise commercializing any
                Website material;
              </li>
              <li>Publicly performing and/or showing any Website material;</li>
              <li>
                Using this Website in any way that is or may be damaging to this
                Website;
              </li>
              <li>
                Using this Website in any way that impacts user access to this
                Website;
              </li>
              <li>
                Using this Website contrary to applicable laws and regulations,
                or in any way may cause harm to the Website, or to any person or
                business entity;
              </li>
              <li>
                Engaging in any data mining, data harvesting, data extracting or
                any other similar activity in relation to this Website;
              </li>
              <li>
                Using this Website to engage in any advertising or marketing.
              </li>
            </ul>
            <h3>4. Your Content</h3>
            <p>
              In these Website Standard Terms and Conditions, “Your Content”
              shall mean any audio, video text, images or other material you
              choose to display on this Website. By displaying Your Content, you
              grant Kales Agro Research and Development Private Limited a
              non-exclusive, worldwide irrevocable, sub-licensable license to
              use, reproduce, adapt, publish, translate and distribute it in any
              and all media.
            </p>
            <h3>5. No warranties</h3>
            <p>
              This Website is provided “as is,” with all faults, and Kales Agro
              Research and Development Private Limited express no
              representations or warranties, of any kind related to this Website
              or the materials contained on this Website. Also, nothing
              contained on this Website shall be interpreted as advising you.
            </p>
            <h3>6. Limitation of liability</h3>
            <p>
              In no event shall Kales Agro Research and Development Private
              Limited, nor any of its officers, directors and employees, shall
              be held liable for anything arising out of or in any way connected
              with your use of this Website whether such liability is under
              contract. Kales Agro Research and Development Private Limited,
              including its officers, directors and employees shall not be held
              liable for any indirect, consequential or special liability
              arising out of or in any way related to your use of this Website.
            </p>
            <h3>7. Indemnification</h3>
            <p>
              You hereby indemnify to the fullest extent Kales Agro Research and
              Development Private Limited from and against any and/or all
              liabilities, costs, demands, causes of action, damages and
              expenses arising in any way related to your breach of any of the
              provisions of these Terms.
            </p>
            <h3>8. Severability</h3>
            <p>
              If any provision of these Terms is found to be invalid under any
              applicable law, such provisions shall be deleted without affecting
              the remaining provisions herein.
            </p>
            <h3>9. Variation of Terms</h3>
            <p>
              Kales Agro Research and Development Private Limited is permitted
              to revise these Terms at any time as it sees fit, and by using
              this Website you are expected to review these Terms on a regular
              basis.
            </p>
            <h3>10. Assignment</h3>
            <p>
              The Kales Agro Research and Development Private Limited is allowed
              to assign, transfer, and subcontract its rights and/or obligations
              under these Terms without any notification. However, you are not
              allowed to assign, transfer, or subcontract any of your rights
              and/or obligations under these Terms.
            </p>
            <h3>11. Entire Agreement</h3>
            <p>
              These Terms constitute the entire agreement between Kales Agro
              Research and Development Private Limited and you in relation to
              your use of this Website, and supersede all prior agreements and
              understandings.
            </p>
            <h3>12. Governing Law & Jurisdiction</h3>
            <p>
              These Terms will be governed by and interpreted in accordance with
              the laws of India and the State of Kerala. You submit to the
              non-exclusive jurisdiction of the High Court of Kerala for the
              resolution of any disputes.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TermsContent
