import React from "react"

const ProductCategories = () => {
  const categories = [
    {
      id: 1,
      image: "assets/images/products/Honey.jpg",
      title: "ORGANIC HONEY",
      link: "/product_details/640725bafae071b1c424d31a",
    },
    {
      id: 2,
      image: "assets/images/products/TenderMango400.jpg",
      title: "VEG & NON.VEG PICKLES",
      link: "/shop",
    },
    {
      id: 3,
      image: "assets/images/products/kalesDryPrawn.jpg",
      title: "DRY FISH",
      link: "/product_details/63a1a113fef2306b7cd82278",
    },
  ]

  return (
    <section
      className="section team"
      id="product-categories"
      style={{ zIndex: 1 }}
    >
      <div id="particles-js" style={{ zIndex: -1 }}></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-4">
              <h2 className="heading">Product Categories</h2>
              <p className="text-muted fs-17">
                Explore our curated selection of handmade food, incense, and
                artisanal products, where each item is a testament to passion
                and quality.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center container-fluid">
          {categories.map((category) => (
            <div key={category.id} className="col-lg-4 col-md-7 mt-sm-4">
              <div data-aos="fade-right" data-aos-duration="1800">
                <div className="feature-card p-3 py-sm-4 rounded d-flex">
                  <div className="content d-block overflow-hidden text-center">
                    <img
                      className="img-fluid"
                      src={category.image}
                      alt="Category"
                    />{" "}
                    <br />
                    <br />
                    <h5 className="title">{category.title}</h5>
                    <a className="btn btn-primary" href={category.link}>
                      Shop Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ProductCategories
