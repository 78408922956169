import React from "react"

const PrivacyContent = () => {
  return (
    <section className="section team" id="features">
      <div id="particles-js" style={{ zIndex: -1 }}></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-8">
              <h2 className="heading">Privacy Policy</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p>
              At Kales Agro Research and Development Private Limited, we value
              your privacy and are committed to protecting your personal data.
              This privacy policy outlines how we collect, use, and protect your
              information.
            </p>

            <h3>1. Information We Collect</h3>
            <p>
              We collect information that you provide to us directly, such as
              when you create an account, make a purchase, or communicate with
              us. This may include your name, email address, phone number, and
              payment information.
            </p>

            <h3>2. How We Use Your Information</h3>
            <p>
              We use the information we collect to provide, maintain, and
              improve our services. This includes processing transactions,
              sending notifications, responding to customer service requests,
              and personalizing your experience.
            </p>

            <h3>3. Sharing Your Information</h3>
            <p>
              We do not share your personal information with third parties
              except as necessary to provide our services or as required by law.
              This may include sharing information with payment processors and
              shipping companies.
            </p>

            <h3>4. Data Security</h3>
            <p>
              We implement appropriate technical and organizational measures to
              protect your personal data from unauthorized access, disclosure,
              alteration, or destruction. However, no internet transmission is
              completely secure, and we cannot guarantee absolute security.
            </p>

            <h3>5. Your Rights</h3>
            <p>
              You have the right to access, correct, or delete your personal
              information. You can also object to the processing of your data or
              request that we restrict processing. To exercise these rights,
              please contact us at the email address provided below.
            </p>

            <h3>6. Cookies</h3>
            <p>
              We use cookies and similar technologies to enhance your experience
              on our website. Cookies are small data files that are placed on
              your device. You can control the use of cookies through your
              browser settings.
            </p>

            <h3>7. Changes to This Policy</h3>
            <p>
              We may update this privacy policy from time to time to reflect
              changes in our practices or legal requirements. We will notify you
              of any significant changes by posting the new policy on our
              website and updating the effective date.
            </p>

            <h3>8. Contact Us</h3>
            <p>
              If you have any questions or concerns about our privacy policy or
              the handling of your personal information, please contact us at:
            </p>
            <ul>
              <li>
                <strong>Email:</strong> privacy@kalesagro.com
              </li>
              <li>
                <strong>Phone:</strong> +91 9744933344
              </li>
              <li>
                <strong>Address:</strong> Kales Agro Research and Development
                Private Limited, Green Tower, 127/D Manakadavu, Pallikkara Road,
                Kakanad, Kerala, 682303
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyContent
