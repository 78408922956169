import React from "react"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap"

const items = [
  {
    id: 1,
    src: "assets/images/home-2.jpeg",
    altText: "Slide 1",
    caption: "Switch to a Healthier lifestyle with Kalesagro Organic Products",
  },
  {
    id: 2,
    src: "assets/images/home-3.jpeg",
    altText: "Slide 2",
    caption: "Switch to a Healthier lifestyle with Kalesagro Organic Products",
  },
  {
    id: 3,
    src: "assets/images/home-4.jpeg",
    altText: "Slide 3",
    caption: "Switch to a Healthier lifestyle with Kalesagro Organic Products",
  },
]

const HomeSection = () => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [animating, setAnimating] = React.useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <div
          className="bg-overlay"
          style={{
            backgroundImage: `url(${item.src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(37, 45, 49, 0.6)",
              zIndex: 1,
            }}
          ></div>
          <div
            className="container"
            style={{ position: "relative", zIndex: 2 }}
          >
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="home-heading">
                  <h1 className="mb-3 text-cursor" style={{ color: "#ccc" }}>
                    {item.caption}
                  </h1>
                </div>
                <div className="home-btn hstack gap-2 flex-column d-sm-block">
                  <a
                    className="btn btn-primary me-1"
                    href="/shop"
                  >
                    View Products
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CarouselCaption captionText="" captionHeader="" />
      </CarouselItem>
    )
  })

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  )
}

export default HomeSection
