import React from "react"

const BlogContent = () => {
  return (
    <div
      className="row col-lg-8 col-md-12 col-sm-12 leftside"
      style={{
        textAlign: "center",
        order: 1,
        border: "2px solid gray",
        padding: "20px",
      }}
    >
      <div data-aos="fade-right" data-aos-duration="1800">
        <div className="text-center">
          <img
            src={"assets/images/products/Honey.jpg"}
            style={{ maxWidth: "100%", height: "auto" }}
            alt="Honey"
          />
          <h3 className="blog-title pt-4">5 Interesting Facts About Honey</h3>

          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div className="date d-flex justify-content-center align-items-center">
              {/* <img
                src={"assets/images/calender-removebg-preview.png"}
                style={{ width: "15px", height: "15px" }}
                alt="Calendar"
              /> */}
              <span> January 30, 2023</span>
            </div>
            <div className="name d-flex justify-content-center align-items-center">
              {/* <img
                src={"assets/images/userrrrr-removebg-preview.png"}
                style={{ width: "15px", height: "15px" }}
                alt="User"
              /> */}
              <span> rstheme</span>
            </div>
          </div>

          <div className="blog-desc pt-4" style={{ textAlign: "justify" }}>
            <p>
              Honey is an amazing and versatile cooking ingredient. It is
              healthy, delicious, and oh-so-sweet! A spoonful of honey can
              instantly transport you to another world with its indulgent taste.
              As a popular tea sweetener, its interesting taste also makes Honey
              a great condiment as well as a wonderful stand-alone snack. You
              can add it to salads for extra flavor or enjoy it on its own as a
              tasty treat.
            </p>

            <b>
              <li>Honey never spoils</li>
            </b>
            <p>
              Honey is the only food that never spoils. It can last for
              thousands of years and still be edible. This is due to the fact
              that the enzymes in honey produce hydrogen peroxide, which acts as
              a natural preservative. Honey is a healthier alternative to sugar
              and can be used in many different recipes.
            </p>

            <b>
              <li>Honey has been popular since medieval times</li>
            </b>
            <p>
              Honey was highly valued in 11th-century Germany for its natural
              sweetness. In fact, honey was popular that it became an accepted
              form of payment for peasants to feudal lords! Honey is said to
              complement the taste of fine wine by adding the perfect amount of
              sweetness.
            </p>

            <b>
              <li>It's Quality over quantity</li>
            </b>
            <p>
              Throughout a honeybee's life, it only produces approximately
              1/12th of a teaspoon of honey. Whether it is buzzing to locations
              unknown, or hard at work these bees are always busy. They work
              tirelessly to collect honey as much as their little bodies would
              allow. This means they need at least a dozen of honeybees to have
              a single tablespoon of honey.
            </p>

            <b>
              <li>Honey is a natural energy booster</li>
            </b>
            <p>
              Honey is a natural and healthy energy booster. It is quickly
              absorbed into the body and provides a quick burst of energy,
              making it a popular ingredient in sports drinks and energy bars.
              Honey is a natural way to boost your energy levels, so you can
              feel your best when participating in your favorite activities.
            </p>

            <b>
              <li>Honey is Medicinal</li>
            </b>
            <p>
              Honey is used as a natural treatment for dandruff, stomach ulcers,
              and even seasonal allergies, etc. Honey is also a popular natural
              remedy for both sore throats and coughing. Its antibacterial
              properties help to kill the bacteria that cause these conditions,
              while its ability to lubricate and soothe the throat helps to
              reduce the symptoms.
            </p>

            <p>
              In conclusion, honey is not only a delicious natural sweetener but
              also a versatile ingredient with a wide range of health benefits.
              From its antibacterial and anti-inflammatory properties to its
              potential to improve heart health and skin appearance and it is a
              valuable addition to your diet, medicine cabinet, and beauty
              routine.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogContent
