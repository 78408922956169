import React from "react"

const Sidebar = () => {
  return (
    <div
      className="row col-lg-4 col-md-12 col-sm-12"
      style={{ display: "flex", flexDirection: "column", order: 2 }}
    >
      <div className="pb-5 leftitemss pt-5">
        <div className="leftitem1">
          <div data-aos="fade-left" data-aos-duration="1800">
            <div
              className="item_1 text-center"
              style={{ border: "2px solid gray", padding: "20px" }}
            >
              <h4>Search Items</h4>
              <form
                method="post"
                onSubmit="return validateForm()"
                className="contact-form"
                name="myForm"
                id="myForm"
              >
                <span id="error-msg"></span>
                <div className="row rounded-3 py-3">
                  <div className="col-lg-12">
                    <div className="position-relative mb-3">
                      <input
                        name="name"
                        id="name"
                        type="text"
                        className="form-control"
                        style={{ paddingLeft: "10px!important" }}
                        placeholder="Search your Item"
                      />
                      <span className="input-group-text" style={{ right: "0" }}>
                        <i className="mdi mdi-magnify"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="leftitem2 col-md-12 col-sm-12">
        <div data-aos="fade-left" data-aos-duration="1800">
          <div
            className="item_1 text-center"
            style={{ border: "2px solid gray", padding: "20px" }}
          >
            <div
              className="title p-3"
              style={{ backgroundColor: "#00776d", color: "#fff" }}
            >
              <h4>Recent Posts</h4>
            </div>

            <div
              className="top-rated-product-item clearfix"
              style={{ paddingTop: "20px" }}
            >
              {[1, 2, 3].map((post, index) => (
                <React.Fragment key={index}>
                  <div
                    className="blog_1"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div className="top-rated-product-img">
                      <a href="product-details.html">
                        <img
                          src={"assets/images/products/Honey.jpg"}
                          alt="#"
                          style={{ width: "70px", height: "81px" }}
                        />
                      </a>
                    </div>
                    <div className="top-rated-product-info">
                      <h6>
                        <a href="product-details.html">
                          Herbal And Organic Farming Products
                        </a>
                      </h6>
                      <div className="product-price">
                        <div className="date d-flex justify-content-center align-items-center">
                          {/* <img
                            src={"assets/images/calender-removebg-preview.png"}
                            style={{ width: "15px", height: "15px" }}
                            alt="Calendar"
                          /> */}
                          <span> January 30, 2023</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {index < 2 && <hr />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 leftitem3">
        <div data-aos="fade-left" data-aos-duration="1800">
          <div
            className="item_1 text-center"
            style={{ border: "2px solid gray", padding: "20px" }}
          >
            <div
              className="title p-3"
              style={{ backgroundColor: "#00776d", color: "#fff" }}
            >
              <h4>Latest Products</h4>
            </div>

            <div
              className="top-rated-product-item clearfix"
              style={{ paddingTop: "20px" }}
            >
              <div
                className="blog_1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {[
                  "assets/images/products/Honey.jpg",
                  "assets/images/products/Chicken400.jpg",
                  "assets/images/products/Dates150.jpg",
                  "assets/images/products/Prawns400.jpg",
                ].map((image, index) => (
                  <div className="top-rated-product-img" key={index}>
                    <a href="product-details.html">
                      <img
                        src={image}
                        alt="#"
                        style={{ width: "60px", height: "63px" }}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
