import React from "react";
import { Triangle } from "react-loader-spinner";
import { RotatingLines } from "react-loader-spinner";

function Loader() {
  return (
    <div className="loader-container">
      {" "}
      <div className="loader-wrapper">
        {/* <Triangle
          visible={true}
          height="160"
          width="160"
          color="#00776d"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
        /> */}
        <RotatingLines
          visible={true}
          height="96"
          width="96"
          color="grey"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </div>
  );
}

export default Loader;
