import React from "react"

const FeaturesSection = () => {
  return (
    <section className="section team" id="features">
      <div id="particles-js" style={{ zIndex: -1 }}></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-8">
              <h2 className="heading">About Kales Agro</h2>
            </div>
          </div>
        </div>
        <p>
          At Kales Agro, we are dedicated to cultivating the land and nourishing
          communities through sustainable agricultural practices. Founded with a
          passion for agriculture and a commitment to environmental stewardship,
          we strive to produce high-quality crops while preserving the natural
          resources for future generations. Our journey began with a vision to
          redefine farming practices, integrating modern technology with
          traditional wisdom to optimize yields while minimizing our ecological
          footprint. Through careful land management, crop rotation, and
          innovative techniques, we prioritize soil health and biodiversity,
          fostering a harmonious ecosystem where crops thrive naturally. What
          sets us apart is our unwavering dedication to transparency and
          integrity. From seed to harvest, we prioritize quality at every stage
          of production, ensuring that only the finest produce reaches your
          table. Our team of skilled farmers, agronomists, and agricultural
          specialists work tirelessly to maintain the highest standards of
          excellence, guided by a deep respect for the land and the communities
          we serve. Community is at the heart of everything we do. We believe in
          fostering strong relationships with our customers, suppliers, and
          neighbors, building a network of support that sustains us all. Whether
          it's through local markets, educational initiatives, or community
          outreach programs, we strive to empower and uplift those around us,
          sharing the bounty of our harvest with gratitude and humility. As we
          look to the future, we remain committed to innovation and growth,
          constantly seeking new ways to improve and evolve. With a spirit of
          collaboration and a reverence for nature, we aim to lead the way
          towards a more sustainable and resilient agricultural future, where
          abundance flourishes in harmony with the planet.
        </p>
        <br />
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-7 mt-sm-4">
            <div data-aos="fade-right" data-aos-duration="1800">
              <div className="feature-card p-3 py-sm-4 rounded d-flex">
                <i className="mdi mdi-checkbox-marked-circle text-primary float-start me-3 h2"></i>
                <div className="content d-block overflow-hidden">
                  <h5 className="title">100% Quality</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-7 mt-sm-4">
            <div data-aos="fade-right" data-aos-duration="1800">
              <div className="feature-card p-3 py-sm-4 rounded d-flex">
                <i className="mdi mdi-checkbox-marked-circle text-primary float-start me-3 h2"></i>
                <div className="content d-block overflow-hidden">
                  <h5 className="title">Chemical Free</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-7 mt-sm-4">
            <div data-aos="fade-left" data-aos-duration="1800">
              <div className="feature-card p-3 py-sm-4 rounded d-flex">
                <i className="mdi mdi-checkbox-marked-circle text-primary float-start me-3 h2"></i>
                <div className="content d-block overflow-hidden">
                  <h5 className="title">Eco-friendly</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-7 mt-sm-4">
            <div data-aos="fade-left" data-aos-duration="1800">
              <div className="feature-card p-3 py-sm-4 rounded d-flex">
                <i className="mdi mdi-checkbox-marked-circle text-primary float-start me-3 h2"></i>
                <div className="content d-block overflow-hidden">
                  <h5 className="title">Natural</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesSection
