import React from "react"

const ShippingPolicyContent = () => {
  return (
    <section className="section team" id="features">
      <div id="particles-js" style={{ zIndex: -1 }}></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-8">
              <h2 className="heading">Shipping Policy</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h3>Shipping Rates & Delivery Estimates</h3>
            <p>
              Shipping charges for your order will be calculated and displayed
              at checkout. Delivery estimates will vary based on your location
              and the shipping method selected.
            </p>

            <h3>Shipment Processing Time</h3>
            <p>
              All orders are processed within 2-3 business days. Orders are not
              shipped or delivered on weekends or holidays. If we are
              experiencing a high volume of orders, shipments may be delayed by
              a few days. Please allow additional days in transit for delivery.
              If there will be a significant delay in the shipment of your
              order, we will contact you via email or telephone.
            </p>

            <h3>Shipment Confirmation & Order Tracking</h3>
            <p>
              You will receive a shipment confirmation email once your order has
              shipped containing your tracking number(s). The tracking number
              will be active within 24 hours.
            </p>

            <h3>Customs, Duties, and Taxes</h3>
            <p>
              KALES AGRO RESEARCH AND DEVELOPMENT PVT LTD is not responsible for
              any customs and taxes applied to your order. All fees imposed
              during or after shipping are the responsibility of the customer
              (tariffs, taxes, etc.).
            </p>

            <h3>International Shipping Policy</h3>
            <p>We currently do not ship outside India.</p>

            <h3>Returns Policy</h3>
            <p>
              Our return and refund policy provides detailed information about
              options and procedures for returning your order.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShippingPolicyContent
