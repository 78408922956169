import React from "react"

const VideoGallery = () => {
  return (
    <div className="container mt-4">
      <div className="text-center mb-5">
        <h2 className="heading">A Journey Through Kales Agro Farm</h2>
      </div>
      <div className="youtube">
        <div className="row">
          <div className="col-md-12 mb-4 col-lg-6">
            <div data-aos="fade-right" data-aos-duration="1800">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  width="100%"
                  height="315"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/nrC4qNqBI8M?si=xfBnIRY-L8ytZPSl"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-4 col-lg-6">
            <div data-aos="fade-left" data-aos-duration="1800">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  width="100%"
                  height="315"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/YCd7gd7iMHE?si=o2eTiTmaREucrFNW"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoGallery
