import React from "react"

const Gallery = () => {
  return (
    <section className="section team" style={{ zIndex: 1, paddingTop: "50px" }}>
      <div id="particles-js" style={{ zIndex: -1 }}></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-5">
              <h2 className="heading">Gallery</h2>
            </div>
          </div>
        </div>
        <div className="row gy-4">
          <div className="col-lg-4 col-sm-6">
            <div className="team-card">
              <div className="team-card-img">
                <img
                  className="img-fluid"
                  src="assets/images/products/Dates150.jpg"
                  alt="Dates"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="team-card">
              <div className="team-card-img">
                <img
                  className="img-fluid"
                  src="assets/images/products/Pineapple150.jpg"
                  alt="Pineapple"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="team-card">
              <div className="team-card-img">
                <img
                  className="img-fluid"
                  src="assets/images/products/bitterg400.jpg"
                  alt="Bitter Gourd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Gallery
