import React from "react"
import CtaSection from "../components/Return/CTASection"
import ReturnRefundContent from "../components/Return/ReturnRefundContent"

const ReturnRefund = () => {
  return (
    <>
      <CtaSection />
      <ReturnRefundContent />
    </>
  )
}

export default ReturnRefund
