import React from "react"

const ReturnRefundContent = () => {
  return (
    <section className="section team" id="features">
      <div id="particles-js" style={{ zIndex: -1 }}></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-8">
              <h2 className="heading">Return and Refund Policy</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h3>Returns</h3>
            <p>
              To ensure that you are satisfied with the product(s) you receive,
              please inspect the contents as soon as your order arrives. Once
              products are delivered to you as per the original order placed,
              there is no refund or exchange possible. As our products are
              perishable, all product sales are final.
            </p>
            <p>
              Kales Agro Research and Development Private Limited will be happy
              to replace any item(s) in the unlikely events such as:
            </p>
            <ul>
              <li>The product might be damaged or spoiled during transit.</li>
              <li>
                The product is not delivered frozen or at the required
                temperature.
              </li>
              <li>The product has a manufacturing quality defect.</li>
            </ul>
            <p>
              In any of the cases mentioned above, please return these products
              to the delivery person along with a remark on the delivery
              receipt. Please contact customer service through the contact us
              link on the website and mention the return of products along with
              the order number and the date of delivery.
            </p>
            <p>
              All returned items must be accompanied by a copy of the original
              receipt. Please note that we cannot offer exchanges or refunds on
              any opened or used products unless found to be defective.
            </p>
            <p>
              Kales Agro Research and Development Private Limited will not be
              responsible for products being undelivered as a result of
              incorrect address information supplied to us or for the inability
              of a recipient to receive the package at the time of delivery.
            </p>

            <h3>Refunds</h3>
            <p>
              Please note that for orders placed using Credit Card, Debit Card &
              Net Banking, the refund once processed may take between one to
              three weeks to reflect on your Credit Card or bank account
              statement. There will be no cash refund.
            </p>

            <h3>Shipping for Return Items</h3>
            <p>
              You will be responsible for paying for your own shipping costs for
              returning your item. Shipping costs are non-refundable. If you
              receive a refund, the cost of return shipping will be deducted
              from your refund.
            </p>

            <h3>Non-Refundable/Non-Returnable Products/Services</h3>
            <p>
              Products that have not been stored in the required storage
              condition of -18°C or due to poor handling after receipt from our
              delivery agent will not be accepted for return or refund.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReturnRefundContent
