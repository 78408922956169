import React from "react"

const MissionVisionSection = () => {
  return (
    <section className="section team" id="mission-vision">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-5">
              <h2 className="heading">Mission & Vision</h2>
            </div>
          </div>
        </div>
        <div className="row my-sm-5 py-5 align-items-center justify-content-between">
          <div className="col-lg-6">
            <div data-aos="fade-right" data-aos-duration="1800">
              <div className="card bg-transparent border-0 mb-3 mb-lg-0">
                <img
                  src={"assets/images/about-1.png"}
                  className="img-fluid rounded-3"
                  alt="Mission"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div data-aos="fade-left" data-aos-duration="1800">
              <h3 className="feature-heading mb-2">Our Mission</h3>
              <p className="text-muted" style={{ textAlign: "justify" }}>
                Kales Agro is an emerging organization which aims at recapturing
                the traditional methods of spreading fragrances through high
                quality dhoop cones, incense sticks along with herbal and
                organic farming and handmade products in compliance with
                national and international quality. We actively promote our
                traditional methods of manufacturing products without chemicals
                and pesticides to spread awareness among the new generation.
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <div data-aos="fade-right" data-aos-duration="1800">
              <h3 className="feature-heading">Our Vision</h3>
              <p className="text-muted" style={{ textAlign: "justify" }}>
                An eco-friendly approach is put forth in creating incense cones,
                and sticks, handmade products to recreate the essence of
                traditional manufacturing methods to preserve the chemical free
                and organic products for our future generations. To increase the
                demand for organically manufactured products among the customers
                is what we look forward to.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div data-aos="fade-left" data-aos-duration="1800">
              <div className="card bg-transparent border-0">
                <img
                  src={"assets/images/about-2.png"}
                  className="img-fluid"
                  alt="Vision"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MissionVisionSection
