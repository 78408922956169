import React from "react"

const CtaSection = () => {
  return (
    <section className="section cta">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-6">
            <div data-aos="fade-down" data-aos-duration="1800">
              <div
                className="cta-heading"
                style={{ marginTop: "50px", marginBottom: "30px" }}
              >
                Return and Refund Policy
              </div>
              <p>Home - Return and Refund Policy</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CtaSection
