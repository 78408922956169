import React from "react"
import HomeSection from "../components/Home/HomeSection"
import FeaturesSection from "../components/Home/FeatureSection"
import ProductCategories from "../components/Home/ProductCategories"
import FeaturedProducts from "../components/Home/FeaturedProducts"
import VideoGallery from "../components/Home/VideoGallery"

function HomePage() {
  return (
    <div
      className="App"
      data-bs-spy="scroll"
      data-bs-target="#navbar"
      data-bs-offset="71"
    >
      <HomeSection />
      <FeaturesSection />
      <ProductCategories />
      <FeaturedProducts />
      <VideoGallery />
    </div>
  )
}

export default HomePage
