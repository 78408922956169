import React from "react"
import CTASection from "../components/Privacy/CTASection"
import PrivacyContent from "../components/Privacy/PrivacyContent"

const PrivacyPolicy = () => {
  return (
    <>
      <CTASection />
      <PrivacyContent />
    </>
  )
}

export default PrivacyPolicy
