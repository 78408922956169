import React from "react"
import CtaSection from "../components/Terms/CTASection"
import TermsContent from "../components/Terms/TermsContent"

const TermsAndConditions = () => {
  return (
    <>
      <CtaSection />
      <TermsContent />
    </>
  )
}

export default TermsAndConditions
