import React from "react"

const VideoGallery = () => {
  const videos = [
    {
      id: 1,
      src: "https://www.youtube.com/embed/nrC4qNqBI8M?si=-rEQ7J8jLpZjaEBZ",
    },
    {
      id: 2,
      src: "https://www.youtube.com/embed/YCd7gd7iMHE?si=h_BC6cP9LcL8OW1T",
    },
    {
      id: 3,
      src: "https://www.youtube.com/embed/njFv7AejlA0?si=lkq-1X2JI1uHXVU4",
    },
    {
      id: 4,
      src: "https://www.youtube.com/embed/hY-LhCRzMb8?si=SjuHq-tmtIRzax0q",
    },
  ]

  return (
    <div className="container mt-4">
      <div className="text-center mb-5">
        <h2 className="heading">Video Gallery</h2>
      </div>
      <div className="youtube">
        <div className="row">
          {videos.map((video) => (
            <div key={video.id} className="col-md-12 mb-4 col-lg-6">
              <div data-aos="fade-right" data-aos-duration="1800">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    width="100%"
                    height="315"
                    className="embed-responsive-item"
                    src={video.src}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default VideoGallery
