import React from "react"

const FeaturedProducts = () => {
  const products = [
    {
      id: 1,
      image: "assets/images/products/Beef150.jpg",
      title: "Beef Pickle",
      link: "/product_details/63ca7381e544b33efac338c8",
    },
    {
      id: 2,
      image: "assets/images/products/Chicken400.jpg",
      title: "Chicken Pickle",
      link: "/product_details/640588d8fae071b1c424c815",
    },
    {
      id: 3,
      image: "assets/images/products/cocCider.jpg",
      title: "Coconut Cider",
      link: "/product_details/650194385971deae7994444f",
    },
    {
      id: 4,
      image: "assets/images/products/Dates150.jpg",
      title: "Dates Pickle",
      link: "/product_details/63a19cabfef2306b7cd82178",
    },
    {
      id: 5,
      image: "assets/images/products/Honey.jpg",
      title: "Organic Honey",
      link: "/product_details/640725bafae071b1c424d31a",
    },
    {
      id: 6,
      image: "assets/images/products/kalesDryPrawn.jpg",
      title: "Dry Prawns Fry",
      link: "/product_details/63a1a113fef2306b7cd82278",
    },
    {
      id: 7,
      image: "assets/images/products/kalesFish.jpg",
      title: "Fish Pickle",
      link: "/product_details/63a18c51fef2306b7cd81fb2",
    },
    {
      id: 8,
      image: "assets/images/products/kalesIncenseCones.jpg",
      title: "Incense Cones",
      link: "/shop",
    },
  ]

  return (
    <section className="team" style={{ zIndex: 1 }}>
      <div id="particles-js" style={{ zIndex: -1 }}></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="text-center mb-5">
              <h2 className="heading">Check our Products</h2>
              <p className="text-muted fs-17">
                Experience the difference that farm-fresh goodness makes, and
                embark on a culinary journey with Kales Agro
              </p>
            </div>
          </div>
        </div>
        <div className="row gy-4">
          {products.map((product) => (
            <div key={product.id} className="col-lg-3 col-sm-6">
              <a href={product.link}>
                <div className="team-card">
                  <div className="team-card-img">
                    <img
                      className="img-fluid"
                      src={product.image}
                      alt="Product"
                    />
                  </div>
                  <div className="team-card-text-2 text-center">
                    <h5 className="fw-bold mb-0 text-primary">
                      {product.title}
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FeaturedProducts
