import React from "react"
import CtaSection from "../components/Shipping/CTASection"
import ShippingPolicyContent from "../components/Shipping/ShippingPolicyContent"

const ShippingPolicy = () => {
  return (
    <>
      <CtaSection />
      <ShippingPolicyContent />
    </>
  )
}

export default ShippingPolicy
