import React from "react"

const FeaturesSection = () => {
  return (
    <section className="section team" id="features" style={{ zIndex: 1 }}>
      <div id="particles-js" style={{ zIndex: -1 }}></div>
      <div className="container">
        <div className="row my-sm-5 py-5 align-items-center justify-content-between">
          <div className="col-lg-6">
            <div data-aos="fade-right" data-aos-duration="1800">
              <div className="card bg-transparent border-0 mb-3 mb-lg-0">
                <img
                  src="assets/images/kales_dav.jpg"
                  className="img-fluid rounded-3"
                  alt="About Kales Agro"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div data-aos="fade-left" data-aos-duration="1800">
              <h3 className="feature-heading mb-2">About Kales Agro</h3>
              <h4 style={{ color: "#00776D" }}>
                <strong>Kales</strong>
              </h4>
              <br />
              <p className="text-muted" style={{ textAlign: "justify" }}>
                At Kales Agro, we are dedicated to cultivating the land and
                nourishing communities through sustainable agricultural
                practices. Founded with a passion for agriculture and a
                commitment to environmental stewardship, we strive to produce
                high-quality crops while preserving the natural resources for
                future generations. Our journey began with a vision to redefine
                farming practices, integrating modern technology with
                traditional wisdom to optimize yields while minimizing our
                ecological footprint. Through careful land management, crop
                rotation, and innovative techniques, we prioritize soil health
                and biodiversity, fostering a harmonious ecosystem where crops
                thrive naturally.
              </p>
              <br />
              <a className="btn btn-primary" href="about.html">
                Read more...
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesSection
